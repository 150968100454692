



import { flutterHasGeolocation, flutterRequestGeolocation } from "apps.modules.FlutterJavaScriptChannels.ts";

export default class GeoLocation {
    static getCurrentPosition(options?: any) {
        return new Promise(async (resolve, reject) => {
            if (flutterHasGeolocation()) {
                try {
                    const data = await flutterRequestGeolocation();
                    resolve({
                        coords: {
                            latitude: data.latitude,
                            longitude: data.longitude,
                            accuracy: data.accuracy,
                        },
                        timestamp: data.timestamp,
                        flutter: true,
                    });
                } catch (err) {
                    reject(err);
                }
            } else {
                if (!navigator?.geolocation?.getCurrentPosition) {
                    reject({ code: -1, message: "geolocation is not supported" });
                    return;
                }

                // only the first resolve/reject is valid

                navigator.geolocation.getCurrentPosition(resolve, reject, options);

                setTimeout(() => {
                    reject({ code: -1, message: "geolocation took too long" });
                }, 10000);
            }
        });
    };
}





// export default class GeoLocation {
//     static getCurrentPosition(options: any) {
//         return new Promise(async (resolve, reject) => {

//             if (!navigator?.geolocation?.getCurrentPosition) {
//                 reject({ code: -1, message: "geolocation is not supported" });
//                 return;
//             }
//             // only the first resolve/reject is valid

//             navigator.geolocation.getCurrentPosition(resolve, reject, options);

//             setTimeout(() => {
//                 reject({ code: -1, message: "geolocation took too long" });
//             }, 10000);

//         });
//     }
// } 
